// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-being-circle-js": () => import("./../../../src/pages/being-circle.js" /* webpackChunkName: "component---src-pages-being-circle-js" */),
  "component---src-pages-business-solutions-js": () => import("./../../../src/pages/business-solutions.js" /* webpackChunkName: "component---src-pages-business-solutions-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-for-individuals-js": () => import("./../../../src/pages/for-individuals.js" /* webpackChunkName: "component---src-pages-for-individuals-js" */),
  "component---src-pages-how-it-works-individuals-js": () => import("./../../../src/pages/how-it-works-individuals.js" /* webpackChunkName: "component---src-pages-how-it-works-individuals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

