import { tailwind } from "@theme-ui/presets"


var baseColors = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  gray: [null, '#f7fafc', '#edf2f7', '#e2e8f0', '#cbd5e0', '#a0aec0', '#718096', '#4a5568', '#2d3748', '#1a202c'],
  red: [null, '#fff5f5', '#fed7d7', '#feb2b2', '#fc8181', '#f56565', '#e53e3e', '#c53030', '#9b2c2c', '#742a2a'],
  orange: [null, '#fffaf0', '#feebc8', '#fbd38d', '#f6ad55', '#ed8936', '#dd6b20', '#c05621', '#9c4221', '#7b341e'],
  yellow: [null, '#fffff0', '#fefcbf', '#faf089', '#f6e05e', '#ecc94b', '#d69e2e', '#b7791f', '#975a16', '#744210'],
  green: [null, '#f0fff4', '#c6f6d5', '#9ae6b4', '#68d391', '#48bb78', '#38a169', '#2f855a', '#276749', '#22543d'],
  teal: [null, '#e6fffa', '#b2f5ea', '#81e6d9', '#4fd1c5', '#38b2ac', '#319795', '#2c7a7b', '#285e61', '#234e52'],
  blue: [null, '#ebf8ff', '#bee3f8', '#90cdf4', '#63b3ed', '#4299e1', '#3182ce', '#2b6cb0', '#2c5282', '#2a4365'],
  indigo: [null, '#ebf4ff', '#c3dafe', '#a3bffa', '#7f9cf5', '#667eea', '#5a67d8', '#4c51bf', '#434190', '#3c366b'],
  purple: [null, '#ffcc00', '#ffcc00', '#ffcc00', '#ffcc00', '#ffcc00', '#ffcc00', '#ffcc00', '#ffcc00', '#ffcc00'],
  pink: [null, '#fff5f7', '#fed7e2', '#fbb6ce', '#f687b3', '#ed64a6', '#d53f8c', '#b83280', '#97266d', '#702459']
};

export default {
  ...tailwind,
  useCustomProperties: true,
  colors: {
    ...baseColors,
    text: "#3d424e",
    heading: baseColors.black,
    primary: `#4B2E6F`,
    background: '#fff',
    divider: baseColors.gray[2],
    textMuted: baseColors.gray[6],
    icon_brightest: baseColors.gray[2],
    icon_darker: baseColors.gray[4],
    icon_darkest: baseColors.gray[6],
    modes: {},
  },
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  sizes: {
    ...tailwind.sizes,
    "1/2": `50%`,
    "1/3": `33.333333%`,
    "2/3": `66.666667%`,
    "1/4": `25%`,
    "2/4": `50%`,
    "3/4": `75%`,
    "1/5": `20%`,
    "2/5": `40%`,
    "3/5": `60%`,
    "4/5": `80%`,
    "1/6": `16.666667%`,
    "2/6": `33.333333%`,
    "3/6": `50%`,
    "4/6": `66.666667%`,
    "5/6": `83.333333%`,
    "1/12": `8.333333%`,
    "2/12": `16.666667%`,
    "3/12": `25%`,
    "4/12": `33.333333%`,
    "5/12": `41.666667%`,
    "6/12": `50%`,
    "7/12": `58.333333%`,
    "8/12": `66.666667%`,
    "9/12": `75%`,
    "10/12": `83.333333%`,
    "11/12": `91.666667%`,
    full: `100%`,
    screen: `100vw`,
  },
  styles: {
    ...tailwind.styles,
    root: {
      ...tailwind.styles.root,
      color: `text`,
      backgroundColor: `background`,
      a: {
        transition: `all 0.3s ease-in-out`,
        fontWeight: 'bold',
        "&:hover": {
          textDecoration: `none`,
        },
      },
      p: {
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
        color: `text`,
      },
      h2: {
      },
      h1: {
        fontSize: '2em',
        fontWeight: 800,
        letterSpacing: '-.025em'
      },
      h3: {
        fontsize: '1.17em',
        fontWeight: 'bold'
      },
      ul: {
        listStyle: 'initial',
        margin: 'inherit',
        paddingLeft: '3em'
      },
    },
    Footer: {
      textAlign: `center`,
      display: `block`,
      position: `absolute`,
      bottom: 0,
      color: `textMuted`,
      px: [2, 3],
      py: [3, 4],
    },
    Container: {
      maxWidth: `5xl`,
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [2, 3],
        fontWeight: 300,
        color: `heading`,
      },
    },
    h1: {
      ...tailwind.styles.h1,
      fontSize: [6, 7, 8],
      mt: 2,
      mb: 4,
      textShadow: `rgba(255, 255, 255, 0.15) 0px 5px 35px`,
      letterSpacing: `wide`,
      color: `heading`,
    },
    h2: {
      ...tailwind.styles.h2,
      fontSize: [4, 5, 6],
      mt: 2,
      color: `heading`,
    },
    h3: {
      ...tailwind.styles.h3,
      fontSize: [3, 4, 5],
      mt: 3,
      color: `heading`,
    },
    h4: {
      ...tailwind.styles.h4,
      fontSize: [2, 3, 4],
      color: `heading`,
    },
    h5: {
      ...tailwind.styles.h5,
      fontSize: [1, 2, 3],
      color: `heading`,
    },
    h6: {
      ...tailwind.styles.h6,
      fontSize: 1,
      mb: 2,
      color: `heading`,
    },
  },
  buttons: {
    toggle: {
      color: `background`,
      border: `none`,
      backgroundColor: `text`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 3,
      py: 2,
      ml: 3,
    },
  },
  texts: {
    bigger: {
      p: {
        fontSize: [2, 3, 4],
      },
    },
  },
}
